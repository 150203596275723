<template>
  <div id="app">     
    <div id="nav">
      <!-- <Home />
      <Services /> -->
    </div>
    <router-view />
    <Footer />
  </div>
</template>

<script>
// import Navbar from "./components/NavbarHome.vue";
import Footer from "./components/Footer.vue";
import Home from "./views/Home.vue";
import Services from "./views/Services.vue";

export default {
  components: { Home, Footer, Services },
};
</script>

<style>
@font-face {
  font-family: "Poppins";
  src: local("Inter"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Italic";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Italic.ttf) format("truetype");
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
