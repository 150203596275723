<template>
  <div>
    <div class="container">
      <div class="titles">
        <h1 class="container">{{$t("Apply3")}}</h1>
        <!-- <p class="container">
          Të gjithë të interesuarit duhet të dërgojnë CV-të dhe kopjen e dokumentave te kerkuara në
          zyret e AL-Petrol në Miradi e Ulët, Fushë Kosovë dhe në email HR@euroshell-ks.com
        </p> -->
      </div>
      <div class="documents">
        <h3 class="container">{{$t("Apply4")}}</h3>
        <!-- <ul class="container">
          <li>Patent shoferin (kategorin C - valide)</li>
          <li>ADR (valide)</li>
          <li>Certifikaten e mjekut (valide)</li>
          <li>Pasaporten (valide)</li>
          <li>Certifikaten CPC (e preferuar)</li>
          <li>Dokumentin qe nuk jeni nenhetime(obligative)</li>
        </ul> -->
        <div>
          <p>{{$t("Apply5")}}</p>
          <p>{{$t("Apply6")}}</p>
          <p>{{$t("Apply7")}}</p>
          <p>{{$t("Apply8")}}</p>
          <p>{{$t("Apply9")}}</p>
          <p>{{$t("Apply10")}}</p>
        </div>
      </div>
    </div>
    <div class="form container">
      <form @submit.prevent="onSubmit" id="form" class="container" action="">
        <h1>{{$t("Apply11")}}</h1>
        <div class="name">
          <label for="First Name" value="First Name">{{$t("Apply12")}}</label>
          <input type="text" v-model="firstName" placeholder="John" />
        </div>
        <div class="lastName">
          <label for="Last Name" value="Last Name">{{$t("Apply13")}}</label>
          <input type="text" v-model="lastName" placeholder="Smith" />
        </div>
        <div class="phoneNumber">
          <label for="Full Name" value="phone Number">{{$t("Apply14")}}</label>
          <input type="text" v-model="phoneNumber" placeholder="+383 44 444 444" />
        </div>
        <div class="email">
          <label for="Email Address">{{$t("Apply15")}}</label>
          <input class="" v-model="email" type="email" placeholder="JohnSmith@gmail.com" />
        </div>
        <div class="cv">
          <label for="">{{$t("Apply16")}}</label>
          <input
            type="file"
            ref="myFiles"
            @change="onChange"
            id="myFile"
            placeholder="attachement.pdf"
            accept="application/pdf"
          />
        </div>
        <div class="message">
          <label for="Message">{{$t("Apply17")}}</label>
          <textarea
            v-model="message"
            name=""
            :placeholder='$t("Apply18")'
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="button">
          <button type="submit">{{$t("Apply19")}}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AWS from "aws-sdk";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      phoneNumber: 0,
      email: "",
      message: "",
      image: "",
    };
  },
  methods: {
    async onSubmit(event) {
      // Create an SES client
      const ses = new AWS.SES({
        accessKeyId: "AKIAZN2PMAGNPVDT4HU2",
        secretAccessKey: "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",
        region: "eu-central-1",
      });

      // Get form data
      var sender = "e.ramadani@digitbusiness.ch";
      var receipient = "e.ramadani@digitbusiness.ch";
      var title = "";
      var content = "";
      var subject = "";
      var filename = "download.pdf";

      // Build the email payload
      var ses_mail = "From: '" + this.email + "' <" + sender + ">\n";
      ses_mail += "To: " + receipient + "\n";
      ses_mail += "Subject: New CV\n";
      ses_mail += "MIME-Version: 1.0\n";
      ses_mail += 'Content-Type: multipart/mixed; boundary="NextPart"\n\n';
      ses_mail += "--NextPart\n";
      ses_mail += "Content-Type: text/html; charset=us-ascii\n\n";
      ses_mail += `This is the body of the email.
    First Name: ${this.firstName} \n,
    Last Name: ${this.lastName} \n,
    Phone Number:  ${this.phoneNumber} \n,
    Email: ${this.email} \n,
    Message: ${this.message}
    \n\n`;
      ses_mail += "--NextPart\n";
      ses_mail += "Content-Type: application/pdf; \n";
      // ses_mail += 'Content-Disposition: attachment; filename="CV.pdf"\n';
      ses_mail += `Content-Disposition: attachment; filename=${this.firstName}_${this.lastName}_CV.pdf\n`;
      ses_mail += "Content-Transfer-Encoding: base64\n\n";
      ses_mail += this.fileData;
      ses_mail += "--NextPart--";
      // Set up the email payload
      const params = {
        RawMessage: {
          Data: ses_mail,
        },
        Destinations: [receipient],
        Source: receipient,
      };

      // Send the email
      ses.sendRawEmail(params).promise();
    },

    onChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        this.fileData = Buffer.from(e.target.result).toString("base64");
      };
    },
  },
};
</script>

<style scoped>
.titles {
  text-align: center;
  width: 100%;
}

.titles h1 {
  /* width: 717px;
  height: 47px; */
  /* UI Properties */
  text-align: center;
  font: normal normal bold 35px/47px Poppins;
  letter-spacing: -1.4px;
  color: #141414;
  opacity: 1;
  margin-top: 41px;
}

.titles p {
  width: 50%;
  /* height: 20px; */
  /* UI Properties */
  text-align: center;
  font: normal normal medium 15px/27px Poppins;
  letter-spacing: 0px;
  color: black;
}

.documents {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  font-weight: 600;
  /* justify-content: center;
	text-align: center; */
  /* margin-left: 22%; */
}

.documents h3 {
  text-align: center;
}

.form {
  width: 45%;
  height: 900px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e8e8e8;
  border-radius: 15px;
  opacity: 1;
  margin-top: 51px;
  padding: 10px 102px;
  margin-bottom: 30px;
}

.form h1 {
  text-align: center;
  margin-top: 43px;
  /* text-align: left; */
  font: normal normal bold 25px/27px Poppins;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}

ul {
  text-align: center;
  font: normal normal normal 18px/35px Poppins;
  letter-spacing: 0px;
  color: #a5a5a5;
  opacity: 1;
}

.name {
  display: flex;
  flex-direction: column;
}

.phoneNumber {
  display: flex;
  flex-direction: column;
}
.email {
  display: flex;
  flex-direction: column;
}
.cv {
  display: flex;
  flex-direction: column;
}
.message {
  display: flex;
  flex-direction: column;
}

label {
  text-align: left;
  font: normal normal bold 12px/27px Poppins;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 14px;
  margin-left: 20px;
}

input {
  width: 100%;
  height: 57px;
  background: #e8e8e8 0% 0% no-repeat padding-box;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  opacity: 1;
  margin-top: 10px;
  padding-left: 20px;
  text-align: left;
  font: normal normal normal 12px/27px Poppins;
  letter-spacing: 0px;
  color: #a8a8a8;
  opacity: 1;
}

textarea {
  width: 100%;
  height: 132px;
  background: #e8e8e8 0% 0% no-repeat padding-box;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  opacity: 1;
  padding-left: 20px;
}

/* form {
	display: flex;
	flex-direction: column;
	align-items: center;
} */

button {
  width: 177px;
  height: 38px;
  /* UI Properties */
  background: #008eff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  color: white;
}
.button {
  display: flex;
  align-self: self-start;
  margin-left: 14%;
  margin-top: 21px;
}

@media screen and (max-width: 960px) {
  input {
    width: 100%;
    height: 57px;
    background: #e8e8e8 0% 0% no-repeat padding-box;
    border: 2px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    margin-top: 10px;
    padding-left: 20px;
  }
  .form {
    width: 100%;
    height: 900px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #e8e8e8;
    border-radius: 15px;
    opacity: 1;
    margin-top: 51px;
  }
}
@media screen and (max-width: 500px) {
  input {
    width: 100%;
    height: 57px;
    background: #e8e8e8 0% 0% no-repeat padding-box;
    border: 2px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    margin-top: 10px;
    padding-left: 20px;
  }
  .form {
    width: 100%;
    height: 900px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #e8e8e8;
    border-radius: 15px;
    opacity: 1;
    margin-top: 51px;
    padding: 10px 20px !important;
    /* margin-bottom: 25%; */
  }

  .titles p {
    width: 90%;
  }
}
</style>
