<template>
  <div>
    <NavbarApply />

    <ApplyForm />
    <!-- <ContactForm /> -->
  </div>
</template>

<script>
// import NavbarContactVue from "../components/Contact/NavbarContact.vue";
// import ContactForm from "../components/Contact/ContactForm.vue";
import NavbarApply from "../components/Apply/NavbarApply.vue";
import ApplyForm from "../components/Apply/ApplyForm.vue";
export default {
  components: { NavbarApply, ApplyForm },
};
</script>
