<template>
  <div>
    <div class="hero">
      <!-- <nav class="navbar navbar-expand-lg nav">
        <a class="navbar-brand logo" href="#">Navbar</a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/services"
                >Services</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/aboutUs">About</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contact">Contact</router-link>
            </li>
          </ul>
        </div>
      </nav> -->
      

      <div id="navbar" :class="{ backgroundi: show }">
        <div class="nav-brand" data-aos="zoom-in" data-aos-duration="1500">
          <router-link to="/" data-aos="zoom-in" data-aos-duration="1500">
            <img style="height: 50px" src="../../assets/Logo.png" alt="" />
          </router-link>
          <b-icon-list  style="right: 0px;" scale="2" class="fa fa-navicon" v-on:click="display"></b-icon-list>
        </div>

        <div class="bar-items" :class="{ hide: !show }">
          <div id="menu" data-aos="zoom-in" data-aos-duration="1500">
            <RouterLink to="/">{{$t("Home")}}</RouterLink>

            <RouterLink to="/services">{{$t("Services")}}</RouterLink>

            <RouterLink to="/aboutUs">{{$t("About")}}</RouterLink>

            <RouterLink to="/contact">{{$t("Contact")}}</RouterLink>
            <div class="language-selector">
            <b-dropdown id="dropdown" drop color="black">
 						 <template #button-content>
    						<b-icon icon="globe" style="width:25px; height:30px;"></b-icon>
  						</template>
              <b-dropdown-item @click="changeL('de')">Deutsch</b-dropdown-item>
        <b-dropdown-item @click="changeL('en')">English</b-dropdown-item>
        <b-dropdown-item @click="changeL('fr')">French</b-dropdown-item>
        <!-- <b-dropdown-item @click="changeL('it')">Italian</b-dropdown-item>
        <b-dropdown-item @click="changeL('fr')">French</b-dropdown-item> -->
        </b-dropdown>
      </div>
           </div>
        </div>
        
      </div>
      

      <!-- 
      <div class="container">
        <div class="nav">
          <b-navbar toggleable="lg" type="dark" variant="" class="navbarr">

            <div>
              <router-link to="/" class="woody navbar-brand">

                Logo
              </router-link>
            </div>
            <div>
              <b-navbar-toggle target="nav-collapse">
                <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>

              </b-navbar-toggle>
              <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                  <b-nav-item to="/" exact>Home </b-nav-item>
                  <b-nav-item to="/services">Services</b-nav-item>
                  <b-nav-item to="/aboutUs">About</b-nav-item>
                  <b-nav-item to="/contact">Contact</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto"> </b-navbar-nav>
              </b-collapse>
            </div>
          </b-navbar>
        </div>
      </div> -->

      <!-- <nav>
        <div class="logo">LogoDsLogistic</div>
        <ul>
          <router-link to="/">
            <li>Home</li>
          </router-link>
          <router-link to="/services">
            <li>Services</li>
          </router-link>
          <router-link to="/aboutUs">
            <li>About us</li>
          </router-link>
          <router-link to="/contact">
            <li>Contact</li>
          </router-link>
        </ul>
      </nav> -->
      <div class="cards container" data-aos="zoom-in" data-aos-duration="1500">
        <div class="content container">
          <h1>{{$t("OurServices")}}</h1>
          <!-- <p>{{$t("Services_p")}}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../assets/style/service.scss";
.fa.fa-navicon {
    display: none;
  }
.nav {
  display: contents;
}
.logo {
  padding-right: 45%;
}

.nav {
  padding-left: 15%;
}
.content h1 {
  /* width: 330px;
  height: 81px; */
  text-align: center;
  font: normal normal bold 61px/81px Poppins;
  letter-spacing: -2.44px;
  color: #ffffff;
  opacity: 1;
  /* margin-left: 25%; */
}

/* 
  .button {
    width: 87px;
    height: 26px;
    text-align: left;
    font: normal normal bold 19px/26px Poppins;
    letter-spacing: 0.38px;
    color: #ffffff;
    opacity: 1;
    margin-left: 35%;
    margin-top: 10%;
  } */

.cards {
  display: flex;
  justify-content: center;
  margin-top: 16%;
}

.content p {
  /* width: 740px;
  height: 33px; */
  text-align: center;
  font: normal normal normal 25px/33px Poppins;
  letter-spacing: -1.25px;
  color: #ffffff;
  opacity: 1;
}
.button button {
  width: 297px;
  height: 53px;
  /* UI Properties */
  background: #008eff 0% 0% no-repeat padding-box;
  opacity: 1;
  /* UI Properties */
  font: normal normal bold 19px/26px Poppins;
  letter-spacing: 0.38px;
  color: #ffffff;
  /* margin-left: 230px; */
  margin-top: 10px;
}

.button {
  text-align: center;
}
.hero {
  height: 878px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: gray;
  background-image: url(../../assets/imagedarken.png);
}

/* .button button {
    width: 297px;
    height: 53px;
    background: #008eff 0% 0% no-repeat padding-box;
    opacity: 1;
  } */

nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
}


nav ul li {
  list-style-type: none;
  display: inline-block;
  padding: 10px 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 776px) {
  .content h1 {
    font: normal normal bold 40px/53px Poppins;
    letter-spacing: -1.6px;
  }

  .hero {
    /* width: 768px; */
    height: 882px;
    /* UI Properties */
    /* background: transparent linear-gradient(179deg, #302a2a 0%, #000000 100%) 0% 0% no-repeat
      padding-box; */
        background-image: url(../../assets/imagedarken.png);
    mix-blend-mode: multiply;
    opacity: 1;
  }

  .content {
    margin-top: 20%;
  }
  ul {
    text-align: center;
    background-color: black;
  }
}
@media screen and (max-width: 390px) {
  .content h1 {
    font: normal normal bold 40px/53px Poppins;
    letter-spacing: -1.6px;
  }
  .b-icon.bi {
    display: inline-block;
  }
  .hero {
    /* width: 768px; */
    height: 882px;
    /* UI Properties */
    /* background: transparent linear-gradient(179deg, #302a2a 0%, #000000 100%) 0% 0% no-repeat
      padding-box; */
     background-image: url(../../assets/imagedarken.png);
    mix-blend-mode: multiply;
    opacity: 1;
  }

  .content {
    margin-top: 50%;
  }

  .hero {
    width: 100%;
  }
}
@media screen and (max-width: 415px) {
  .content h1 {
    font: normal normal bold 40px/53px Poppins;
    letter-spacing: -1.6px;
  }

  .hero {
    /* width: 768px; */
      background-image: url(../../assets/imagedarken.png);
    height: 882px;
    /* UI Properties */
    /* background: transparent linear-gradient(179deg, #302a2a 0%, #000000 100%) 0% 0% no-repeat
      padding-box; */
    mix-blend-mode: multiply;
    opacity: 1;
  }

  .content {
    margin-top: 50%;
  }

  .hero {
    width: 100%;
  }
  ul {
    text-align: center;
    background-color: black;
  }
}
@media screen and (max-width: 430px) {
  .b-icon.bi {
    display: inline-block;
  }
  .content h1 {
    font: normal normal bold 32px/53px Poppins;
    letter-spacing: -1.6px;
  }
  .content p {
    text-align: center;
    font: normal normal normal 20px/27px Poppins;
    letter-spacing: -1px;
    color: #ffffff;
    opacity: 1;
}

  .hero {
      background-image: url(../../assets/imagedarken.png);
    /* width: 768px; */
    height: 882px;
    /* UI Properties */
    /* background: transparent linear-gradient(179deg, #302a2a 0%, #000000 100%) 0% 0% no-repeat */
      /* padding-box; */
    mix-blend-mode: multiply;
    opacity: 1;
  }

  .content {
    margin-top: 50%;
  }

  .hero {
    width: 100%;
  }
  ul {
    text-align: center;
    background-color: black;
  }
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
}
h1 {
  text-align: center;
  font-size: 36px;
  text-transform: capitalize;
}
h2 {
  padding-left: 15px;
  color: #fff;
  margin: 0;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  width: 100%;
}

#menu {
  list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: -5px;
    padding: 0;
    /* margin-bottom: -24px; */
    margin-top: 30px;
}

.bar-items {
}

a {
  padding: 20px;
}

li:hover {
  background-color: #42d392;
}

a {
  text-decoration: none;
  font-size: 18px;
  color: #fff !important;
  white-space: nowrap;
}

.fa {
  display: none;
}
.test {
  display: none;
}

@media (max-width: 780px) {
  .b-icon.bi {
    display: inline-block;
  }
  #navbar {
    flex-direction: column;
  }
  .backgroundi {
    background-color: rgb(99, 99, 99) !important;
  }
  .backroundi {
    background: cornflowerblue;
  }

  .fa {
    display: block;
    padding: 0 25px;
    position: absolute;
    right: 65;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    margin-top: 30px;
  }

  #menu {
    flex-direction: column;
    padding: 5px 0;
  }

  a {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
  }

  .hide {
    display: none;
  }

  .nav-brand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 15px 0px; */
  }
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters(["getLanguage"]),
    language() {
      if (this.getLanguage === "en") {
        return "English";
      } else if (this.getLanguage === "de") {
        return "Deutsch";
      } else if (this.getLanguage === "it") {
        return "Italian";
      } else if (this.getLanguage === "fr") {
        return "French";
      }
      return "Shqip";
    },
  },
  methods: {
    ...mapActions(["changeLanguage"]),

    changeL(lang) {
      this.changeLanguage(lang);
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
      }
    },

    display() {
      this.show = !this.show;
    },
  },
};
</script>
