<template>
  <div class="container d-flex container all">
    <div class="container information">
      <div class="infoo">
        <h1>{{$t("Get_in_touch")}}</h1>
        <div>
          <div class="phone" data-aos="zoom-in" data-aos-duration="1500">
            <b-icon
              class="phoneIcon"
              icon="telephone-fill"
              aria-hidden="true"
            ></b-icon>
            <p><a style="text-decoration: none; color: #a5a5a5;" href="tel:032 671 20 10">032 671 20 10</a></p>
          </div>
          <div class="info" data-aos="zoom-in" data-aos-duration="1500">
            <b-icon
              style="color: black"
              class="mailIcon"
              icon="envelope-fill"
              aria-hidden="true"
            ></b-icon>
            <p><a style="text-decoration: none; color: #a5a5a5;" href="mailto:contact@dslogistik.ch">contact@dslogistik.ch</a></p>
          </div>
          <div class="street" data-aos="zoom-in" data-aos-duration="1500">
            <b-icon
              class="mapIcon"
              icon="pin-map-fill"
              aria-hidden="true"
            ></b-icon>
            <p><a style="text-decoration: none; color: #a5a5a5;" href="https://www.google.com/maps/place/Bahnhofstrasse+276,+4563+Gerlafingen,+Switzerland/@47.1702525,7.5607443,17z/data=!3m1!4b1!4m6!3m5!1s0x478e284e2a1131ef:0x91e7e66b8a90014a!8m2!3d47.1702489!4d7.562933!16s%2Fg%2F11c1x38w0r?hl=en">Bahnhofstrasse 276, 4563 Gerlafingen, Switzerland</a></p>
          </div>
        </div>
      </div>
      <div class="map" data-aos="zoom-in" data-aos-duration="1500">
        <iframe
          class="mapp"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.3669040726704!2d7.560744316024751!3d47.17025252601069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e284e2a1131ef%3A0x91e7e66b8a90014a!2sBahnhofstrasse%20276%2C%204563%20Gerlafingen%2C%20Switzerland!5e0!3m2!1sen!2s!4v1679917968397!5m2!1sen!2s"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <div class="container contactForm" style="padding-left: 30px; margin-right: 24px;" >
      <form class="container" action="" @submit.prevent="submitForm" ref="form" >
        <div class="name" data-aos="zoom-in" data-aos-duration="1500">
          <label for="Full Name" value="Full Name">{{ $t("Full_name") }}</label>
          <input type="text" name="fullName" v-model="fullName" placeholder="John Smith" />
        </div>
        <div class="email" data-aos="zoom-in" data-aos-duration="1500">
          <label for="Email Address">{{$t("Email")}}</label>
          <input
            class=""
            type="email"
            name="email"
            v-model="email"
            placeholder="JohnSmith@gmail.com"
          />
        </div>
        <div class="message" data-aos="zoom-in" data-aos-duration="1500">
          <label for="Message">{{$t("Message")}}</label>
          <textarea
            name="message"
            v-model="message"
            :placeholder="$t('Type_Message')"
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <button data-aos="zoom-in" data-aos-duration="1500">{{ $t("Button_message") }}</button>
      </form>
    </div>
  </div>
</template>

<script>

import AWS from "aws-sdk";

export default {
  data() {
    return {
      fullName: "",
      email: "",
      message: "",
    };
  },
  methods: {
    // readFile() {
    //   const file = this.$refs.fileInput.files[0];

    //   const reader = new FileReader();
    //   reader.onload = (event) => {
    //     console.log(event.target.result);
    //   };
    // },
    async submitForm() {
      // Create an SES client
      const ses = new AWS.SES({
        accessKeyId: "AKIAZN2PMAGNPVDT4HU2",
        secretAccessKey: "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",
        region: "eu-west-3",
      });

      // Configure the SES sendEmail request
      const params = {
        Destination: {
          ToAddresses: ["contact@dslogistik.ch"],
        },
        Message: {
          Body: {
            Text: {
              //   Charset: "UTF-8",
                     Data: `Emri: ${this.fullName}
                    Email Address:${this.email}
                    Short Message: ${this.message}
                    `,
            },
          },
          Subject: {
            Data: "Form submission from Kosovo website",
          },
        },
        Source: "contact@dslogistik.ch",
      };

      // Send the email
      try {
        await ses.sendEmail(params).promise();
        console.log("Email sent successfully");
      } catch (err) {
        console.error(err);
      }
      this.resetForm();
    },
    resetForm() {
      (this.fullName = ""), (this.email = ""), (this.message = "");
    },
  },
};

</script>

<style scoped>
.contactForm {
  width: 100%;
  height: 784px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -40px 30px 45px #1414141c;
  border-radius: 12px;
  opacity: 1;
  padding-left: 60px;
}

form {
  margin-top: 47px;
}

input {
  width: 100%;
  height: 67px;
  /* UI Properties */
  box-shadow: 0px 8px 10px #00000029;
  border: 1px solid #a5a5a5;
  border-radius: 8px;
  opacity: 0.63;
  margin-top: 13px;
  padding: 21px;
}

textarea {
  width: 100%;
  height: 287px;
  /* UI Properties */
  box-shadow: 0px 8px 10px #00000029;
  border: 1px solid #a5a5a5;
  border-radius: 8px;
  opacity: 0.63;
  margin-top: 13px;
  padding: 21px;
}

.name {
  display: flex;
  flex-direction: column;
}
.email {
  display: flex;
  flex-direction: column;
}
.message {
  display: flex;
  flex-direction: column;
}

label {
  /* width: 58px;
  height: 20px; */
  /* UI Properties */
  text-align: left;
  font: normal normal bold 15px/20px Poppins;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 38px;
}

button {
  width: 353px;
  height: 53px;
  /* UI Properties */
  background: #008eff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-top: 40px;
  color: white;
}

.information {
  border-radius: 8px;
  margin-top: 13px;
  padding: 21px;
  color: black;
}

.information h1 {
  width: 100%;
  height: 37px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 28px/37px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
}

.phone .info .street {
  margin-top: 40px;
}

.phone p {
  /* width: 165px;
  height: 27px; */
  /* UI Properties */
  text-align: left;
  font: normal normal normal 20px/27px Poppins;
  letter-spacing: 0px;
  color: #a5a5a5;
  opacity: 1;
}

.info p {
  text-align: left;
  font: normal normal normal 20px/27px Poppins;
  letter-spacing: 0px;
  color: #a5a5a5;
  opacity: 1;
}

.street p {
  text-align: left;
  font: normal normal normal 20px/27px Poppins;
  letter-spacing: 0px;
  color: #a5a5a5;
  opacity: 1;
}

.map {
  margin-top: 68.12px;
  width: 547px;
  height: 318px;
  /* UI Properties */
  opacity: 1;
  background-color: gray;
}
.all {
  margin-top: 46px;
}
.phoneIcon {
  width: 23px;
  height: 23px;
  /* UI Properties */
  /* background: #000000 0% 0% no-repeat padding-box; */
  /* border: 1px solid #000000; */
  opacity: 1;
  margin-top: 88.5px;
}

.mailIcon {
  width: 30px;
  height: 20px;
  opacity: 1;
  margin-top: 42.83px;
}

.mapIcon {
  width: 18px;
  height: 26px;
  /* UI Properties */
  /* background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #000000; */
  opacity: 1;
  margin-top: 39.83px;
}

.mapp {
  width: 547px;
  height: 318px;
}

@media screen and (max-width: 1420px) {
  .all {
    display: flex;
    flex-direction: column;
  }
  .information {
    display: flex;
    justify-content: space-between;
  }
  .mapp {
    /* width: 1100px;
    height: 318px; */
  }
}
@media screen and (max-width: 1100px) {
  .all {
    display: flex;
    flex-direction: column;
  }
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mapp {
    width: 100%;
    height: 318px;
  }

  .map {
    margin-left: 33px;
  }
  .contactForm {
    width: 100%;
    height: 750px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -40px 30px 45px #1414141c;
    border-radius: 12px;
    opacity: 1;
  }

  input {
    width: 100%;
    height: 62px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 8px;
    opacity: 0.63;
  }

  textarea {
    width: 100%;
    height: 266px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 8px;
    opacity: 0.63;
  }

  button {
    width: 50%;
    height: 50px;
    justify-content: center;
    /* UI Properties */
    background: #008eff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }

  iframe {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .all {
    display: flex;
    flex-direction: column;
  }
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mapp {
    width: 900px;
    height: 318px;
  }
  .contactForm {
    width: 100%;
    height: 622px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -40px 30px 45px #1414141c;
    border-radius: 6px;
    opacity: 1;
  }

  input {
    width: 350px;
    height: 35px;
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  textarea {
    width: 350px;
    height: 182px;
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  button {
    width: 90%;
    height: 35px;
    background: #008eff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }

  h1 {
    text-align: left;
    font: normal normal bold 20px/27px Poppins;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
  }
  .infoo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mapp {
    width: 100%;
  }
  .map {
    width: 100%;
    margin-left: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 430px) {
  .phone{
    margin-top: 25px;
  }
  .all {
    display: flex;
    flex-direction: column;
  }
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
 
  .contactForm {
    width: 100%;
    height: 622px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -40px 30px 45px #1414141c;
    border-radius: 6px;
    opacity: 1;
  }

  input {
    width: 300px;
    height: 35px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  textarea {
    width: 300px;
    height: 182px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  button {
    width: 90%;
    height: 35px;
    /* UI Properties */
    background: #008eff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }

  h1 {
    text-align: left;
    font: normal normal bold 19px/27px Poppins;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
  }
  .infoo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mapp {
    width: 100%;
  }
  .map {
    width: 100%;
    margin-left: 0px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phone p {
    /* width: 165px;
  height: 27px; */
    /* UI Properties */
    text-align: left;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
  }
}
/* @media screen and (max-width: 992px) {
  .all {
    display: flex;
    flex-direction: column;
  }
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mapp {
    width: 900px;
    height: 318px;
  }
} */

@media screen and (max-width: 415px) {
  .all {
    display: flex;
    flex-direction: column;
  }
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mapp {
    width: 900px;
    height: 318px;
  }
  .contactForm {
    width: 100%;
    height: 622px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -40px 30px 45px #1414141c;
    border-radius: 6px;
    opacity: 1;
  }

  input {
    width: 300px;
    height: 35px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  textarea {
    width: 300px;
    height: 182px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  button {
    width: 90%;
    height: 35px;
    /* UI Properties */
    background: #008eff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }

  h1 {
    text-align: left;
    font: normal normal bold 19px/27px Poppins;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
  }
  .infoo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mapp {
    width: 350px;
  }
  .map {
    width: 300px;
    margin-left: 0px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .phone p {
    /* width: 165px;
  height: 27px; */
    /* UI Properties */
    text-align: left;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
  }
}
@media screen and (max-width: 390px) {
  .all {
    display: flex;
    flex-direction: column;
  }
  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mapp {
    width: 100%;
    height: 318px;
  }
  .contactForm {
    width: 100%;
    height: 622px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -40px 30px 45px #1414141c;
    border-radius: 6px;
    opacity: 1;
  }

  input {
    width: 240px;
    height: 35px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  textarea {
    width: 240px;
    height: 182px;
    /* UI Properties */
    box-shadow: 0px 8px 10px #00000029;
    border: 1px solid #a5a5a5;
    border-radius: 4px;
    opacity: 0.63;
  }

  button {
    width: 90%;
    height: 35px;
    /* UI Properties */
    background: #008eff 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }

  h1 {
    text-align: left;
    font: normal normal bold 20px/27px Poppins;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
  }
  .infoo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mapp {
    /* width: 300px; */
  }
  .map {
    width: 100%;
    margin-left: 0px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phone p {
    /* width: 165px;
  height: 27px; */
    /* UI Properties */
    text-align: left;
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
  }
}

</style>
