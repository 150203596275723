import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import AWS from "aws-sdk";
import VueI18n from "vue-i18n";
import en from "./i18n/en.json";
import de from "./i18n/de.json";
import it from "./i18n/it.json";
import fr from "./i18n/fr.json";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(AWS);

Vue.config.productionTip = false;

Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: store.state.language.lang,
	// fallbackLocale: "de",
	messages: {
		en,
		de,
		it,
		fr,
	},
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted(){
	AOS.init();
  },
}).$mount("#app");
