<template>
  <div class="container" animation="fade-in">
    <div class="title" data-aos="zoom-in" data-aos-duration="1500" >{{$t("OurServices")}}</div>
    <VueSlickCarousel v-if="getLanguage === 'en'" class="carousel" v-bind="settings" >
      <img
        src="./../../assets/images/services/service1EN.svg"
        alt="DSLogisticService"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/Group 15.svg"
        alt="DSLogisticServicess"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service3EN.svg"
        alt="DSLogisticServicess"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service4EN.svg"
        alt="DSLogisticServices"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service5EN.svg"
        alt="DSLogisticServicessss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service6EN.svg"
        alt="DSLogisticServicessssssss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service7EN.svg"
        alt="DSLogisticServicessssssss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
    </VueSlickCarousel>
    <VueSlickCarousel v-if="getLanguage === 'de'" class="carousel" v-bind="settings">
      <img
        src="./../../assets/images/services/service1DE.svg"
        alt="DSLogisticService"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service2DE.svg"
        alt="DSLogisticServicess"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service3DE.svg"
        alt="DSLogisticServicess"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service4DE.svg"
        alt="DSLogisticServices"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service5DE.svg"
        alt="DSLogisticServicessss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/images/services/service6DE.svg"
        alt="DSLogisticServicessssssss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/srv7.svg"
        alt="DSLogisticServicessssssss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
    </VueSlickCarousel>
    <VueSlickCarousel v-if="getLanguage === 'fr'" class="carousel" v-bind="settings">
      <img
        src="./../../assets/Group 69.svg"
        alt="DSLogisticService"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/Group 73.svg"
        alt="DSLogisticServicess"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/Group 70.svg"
        alt="DSLogisticServicess"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/Group 74.svg"
        alt="DSLogisticServices"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/Group 71.svg"
        alt="DSLogisticServicessss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/Group 75.svg"
        alt="DSLogisticServicessssssss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
      <img
        src="./../../assets/Group 72.svg"
        alt="DSLogisticServicessssssss"
        data-aos="zoom-in" data-aos-duration="1500"
      />
    </VueSlickCarousel>
  </div>
</template>
<script>
import {  mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: true,
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
              arrows: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 360,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getLanguage"]),
  },
};
</script>

<style scoped>
@import "./../../assets/style/service.scss";

.title {
  width: 100%;
  height: 47px;
  text-align: center;
  font: normal normal bold 35px/47px Poppins;
  letter-spacing: -1.4px;
  color: #141414;
  opacity: 1;
}
.carousel {
  padding-top: 71px;
}
button.slick-next:before {
  color: red !important;
}
.container {
  margin-top: 50px;
  margin-bottom: 50px;
}
div {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
img {
  height: 618px;
}

@media screen and (max-width: 768px) {
  /* div {
    width: 90%;
    height: 90%;
  } */
  img {
    width: 100%;
    height: 600px;
  }
}
@media screen and (max-width: 600px) {
  /* div {
    width: 90%;
    height: 90%;
  } */
  img {
    width: 100%;
    height: 500px;
  }
}
@media screen and (max-width: 460px) {
  /* div {
    width: 90%;
    height: 90%;
  } */
  img {
    width: 100%;
    height: 450px;
  }
}
@media screen and (max-width: 415px) {
  img {
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 430px) {
  img {
    width: 100%;
    height: 400px;
  }
}
</style>
